.player-wrapper {
    position: relative;
    height: 100%;
    width: 100%;
  }
  
  .react-player {
    
    position: fixed;
    top:0;
    bottom: 8%;
    left: 0;
    right: 0;
    
    margin: auto;
  }

  .cursorHover:hover  {
    cursor: pointer;
  }

  
  .ril-caption  {

  }

  .ril-inner  {

  }
  .ril-image-current  {

  }
  .ril-image-next
  {


  }
  .ril-image-prev
  {

  }
  .ril-caption-content  {
    margin:auto;
    max-width: 50rem;
    font-size: 1.15em;
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }
